<template>
    <v-card class="message pa-3 pb-0" flat>
        <v-layout row wrap class="pa-5">
          <v-flex xs12 md5>
            <div class="grey--text mb-5">{{ $t('newAdminCourses.question') }}</div>
            <v-layout wrap justify-center align-center>
              <v-flex xs12 md9>
                <v-textarea v-model="question.content" :readonly="isQuestionEdit !== question.id" 
                v-bind:class="{ active: isQuestionEdit === question.id }" auto-grow rows="2"></v-textarea>
              </v-flex>

                <Buttons 
                  v-if="isAdmin"
                  :isEdit="isQuestionEdit" 
                  :id="question.id"
                  :deleteBtn="true"
                  @save="saveQuestion"
                  @remove="$emit('remove')"
                  @edit="isQuestionEdit = question.id"
                  @cancel="isQuestionEdit = -1"
                />
            </v-layout>
          </v-flex>
          <v-flex xs12 md7 class="pa-5">
            <div class="grey--text mb-1">{{ $t('newAdminCourses.answers') }}</div>
            <v-layout wrap justify-center align-center class="answer px-2" v-for="answer in question.answers" :key="answer.id"> 
              <v-flex xs12 md6>
                <v-text-field :label="$t('newAdminCourses.answer')" v-model="answer.content" :readonly="isEdit !== answer.id" v-bind:class="{ active: isEdit === answer.id }"></v-text-field>
              </v-flex>
              <v-flex xs12 md3 class="px-2">
                <v-select
                  v-model="answer.correct"
                  :items="correct"
                  item-text="status"
                  item-value="value"
                  :readonly="isEdit !== answer.id"
                ></v-select>
              </v-flex>
              <Buttons 
                v-if="isAdmin"
                :isEdit="isEdit" 
                :id="answer.id"
                :deleteBtn="true"
                @save="saveAnswer(answer)"
                @edit="isEdit = answer.id"
                @remove="$emit('removeAnswer', { id: answer.id })"
                @cancel="isEdit = -1"
              />
            </v-layout>
            <v-layout wrap justify-start align-center class="px-2" v-for="(answer, index) in newAnswers" :key="index"> 
              <v-flex xs12 md6>
                <v-text-field v-model="answer.content" :label="$t('newAdminCourses.answer')" v-bind:class="{ active: isEdit === answer.id }"></v-text-field>
              </v-flex>
              <v-flex xs12 md3 class="px-2">
                <v-select
                  v-model="answer.correct"
                  :items="correct"
                  item-text="status"
                  item-value="value"
                ></v-select>
              </v-flex>
              <v-icon @click="cancelNewAnswer(index)" class="delete-icon">{{ closeIcon }}</v-icon>
            </v-layout>
            <v-layout v-if="isAdmin" row justify-center align-center>
              <v-btn class="ma-2 white--text" @click="addNewAnswer()" :color="this.YColor">
                {{ $t('newAdminCourses.newAnswer') }}
              <!-- <v-icon>{{this.plusIcon}}</v-icon> -->
              </v-btn>
              <v-btn @click="saveNewAnswers()" class="ma-2 white--text" :color="this.primaryColor">
                {{ this.$t("newAdminCourses.saveAnswers") }}
                <!-- <v-icon>{{ answerIcon }}</v-icon> -->
              </v-btn>
            </v-layout>
            
          </v-flex>
        </v-layout>
      <v-divider class="ma-0 mt-3"></v-divider>
      </v-card>
</template>
<script>
import Buttons from './Buttons'
import moment from "moment";
import { mapActions, mapState } from 'vuex';

export default {
  components: { Buttons },
  name: 'Question',
  props: [
    'question'
  ],

  data () {
    return {
      isEdit: -1,
      isQuestionEdit: -1,
      newAnswers: [],
      correct: [
        {
          status: this.$t('newAdminCourses.correct'),
          value: 1
        },
        {
          status: this.$t('newAdminCourses.wrong'),
          value: 0
        }
      ]
    }
  },

  computed: {
    isAdmin () {
      let admin = (localStorage.getItem('adminType') === 'super' || localStorage.getItem('adminType') === 'normal');
      let notDenied = localStorage.getItem('courseStatus') === 'notDenied' ;
      return (admin && notDenied)
    },
  },

  methods: {
    ...mapActions("Courses/Questions", ["updateQuestion", "updateAnswer", "addAnswers"]),
    saveQuestion () {
      this.updateQuestion({ id: this.question.id, data: {type: 'oneChoice', content: this.question.content }}).then(() => {
        this.isQuestionEdit = -1;
        this.$emit('refresh')
      })
    },
    saveAnswer (answer) {
      this.updateAnswer({ id: answer.id, data: {content: answer.content, correct: answer.correct }}).then(() => {
        this.isEdit = -1;
        this.$emit('refresh')
      })
    },
    addNewAnswer () {
      let newAnswer = { content: '', correct: 0};
      this.newAnswers.push(newAnswer);
      // this.isEdit = undefined;
    },
    saveNewAnswers () {
      this.addAnswers({data: {answers: this.newAnswers}, id: this.question.id}).then(() => {
        this.isEdit = -1;
        this.newAnswers = []
        this.$emit('refresh')
      })
    },
    cancelNewAnswer (index) {
      this.newAnswers.splice(index, 1);
    }
  },

  filters: {
    moment: function(date) {
      return moment(date).format("YYYY-MM-DD");
    }
  }
}
</script>
<style lang="scss" scoped>
.message {
  width: 100%;
  border-bottom: 1px gray solid;
}

.message:nth-of-type(even) {
  border-left: 4px gray solid;
  border-right: 4px gray solid;
}
@media (max-width : 960px) {
  .message:nth-of-type(odd) {
    background-color: lightgray;
  }
  .answer {
    padding: 1rem;
    margin-bottom: 2rem;
    border: 2px gray solid;
    border-radius: 5px;
  }
}
.message:nth-of-type(odd) {
  border-left: 4px teal solid;
  border-right: 4px teal solid;
}
.active {
  color: teal !important;
  font-size: 1.5rem;
}
</style>